import React from "react"
import Icon from "../icon"
import { useBreakpoint } from "gatsby-plugin-breakpoints"


const socialMediaIcons = {
  'youtube': 'icons-32-x-32-export-youtube',
  'facebook': 'icons-32-x-32-export-facebook',
  'twitter': 'twitter-x-seeklogocom-4',
  'instagram': 'icons-32-x-32-export-instagram',
  'linkedin': 'icons-32-x-32-export-linkedin',
  'podcaster': 'icons-32-x-32-export-podcaste',
  'pinterest': 'icons-32-x-32-export-pinterest',
}

const InteriorContactItem = (props) => {

  const { icon, text, socialmedia, className } = props
  const array = isArray(text)
  const breakpoints = useBreakpoint()


  const getSocialMediaName = (url) => {
    const domain = url.split("://")[1].replace("www.", ""); // Extract the domain after the protocol and remove "www." if present
    const socialMediaName = domain.split(".")[0]; // Extract the subdomain as the social media name
    return socialMediaName.toLowerCase(); // Convert to lowercase for consistent matching
  };

  const getSocialMediaIcons = (urls) => {
    const socialMediaNames = urls.map(url => getSocialMediaName(url)); // Extract the social media names from the URLs
    const uniqueSocialMediaNames = [...new Set(socialMediaNames)]; // Get the unique social media names from the list

    const icons = uniqueSocialMediaNames.map(socialMediaName => socialMediaIcons[socialMediaName]); // Get the corresponding icons for the social media names

    return icons;
  }

  const getIcon = (url) => {
    const socialMediaName = getSocialMediaName(url);
    const icon = socialMediaIcons[socialMediaName];
    return icon;
  };



  return(
    <div className={`m-b-24 medium-m-b-18 medium-50 medium-float-l ${className}`}>
      <span className={`icon-wrapper label ${icon === "icons-32-x-32-export-share" ? 'align-bottom' : ''} `}>
        {
          icon === "icons-32-x-32-export-share"
          ?
          <span className="connect-social-media">Connecta't</span>
          :
            <Icon
              icon={icon}
              size={breakpoints.md ? 24 : 32}
              color="orange"
              className={`${icon} orange`}
            />
        }

      </span>
      {
        array
        ?
        <>

        {

        socialmedia && socialmedia.length

        ?
        <span className="text-wrapper values">{/* global social media*/}
          {socialmedia.map((item, index) => {
            return(
              <span className="icon-wrapper" key={index}>
                <a href={item?.url} target="_blank" alt={item?.url} title={item?.url}>
                  <Icon
                    icon={getIcon(item?.url)}
                    size={breakpoints.md ? 24 : 32}
                    color="orange"
                    className={`${getIcon(item?.url)} `}
                  />
                </a>
              </span>
            )
          })}
        </span>
        :
        <span className="text-wrapper share-social-media">{/* global social media*/}
          {text.map((item, index) => {
            return(
              <span className="icon-wrapper" key={index}>
                <a href={item?.url} target="_blank" alt={item?.field_name} title={item?.field_name}>
                  <Icon
                    icon={item?.icon.replace("icons-32-x-32-export-twitter", "twitter-x-seeklogocom-4" )}
                    size={breakpoints.md ? 24 : 32}
                    color="orange"
                    className={`${item?.icon} `}
                  />
                </a>
              </span>
            )
          })}
        </span>
        }

        </>
        :
        <span className={`${icon} text-wrapper ${text.length > 17 ? 'medium-75p' : ''}`}>
        {text}
        </span>
      }
    </div>
  )
}

export const isArray = (arg) => {
    if(typeof arg === "string"){
      return false;
    }
    return true;
}

export default InteriorContactItem
