import React, { useContext, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { homePagePaths } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Description from "../components/section/section-description"
import { LanguageContext } from "../context"
import InteriorContactItem from "../components/interior-contact-item"
import Seo from "../components/seo"
import MediaList from "../components/media-list"
//import MinistryProjects from "../components/ministries/ministry-projects"



const contactItems = [
  {
    'key': 1,
    'icon': 'icons-32-x-32-export-clock',
    'text': 'De 9:00 - 15:00',
    'field_name': 'schedule'
  },
  {
    'key': 2,
    'icon': 'icons-32-x-32-export-phone-call',
    'text': '(+34) 937 881 661',
    'field_name': 'phone'
  },
  {
    'key': 3,
    'icon': 'icons-32-x-32-export-mail',
    'text': 'info@fundaciogoel.es',
    'field_name': 'email'

  },
  {
    'key': 4,
    'icon': 'icons-32-x-32-export-maps',
    'text': 'Avinguda de Béjar 299, 08226 Terrassa',
    'field_name': 'address'
  },
  {
    'key': 5,
    'icon': 'icons-32-x-32-export-share',
    'field_name': 'social_media',
    'text': [
        {
          'icon': 'icons-32-x-32-export-twitter',
          'url': 'https://twitter.com/fundaciogoel',
          'field_name': "Twitter"
        },
        {
          'icon': 'icons-32-x-32-export-facebook',
          'url': 'https://www.facebook.com/goel.fundacio',
          'field_name': "Facebook"
        },
        {
          'icon': 'icons-32-x-32-export-instagram',
          'url': 'https://www.instagram.com/fundaciogoel',
          'field_name': "Instagram"
        }
    ]
  }
]

const PageProject = ({data, location, pageContext }) => {
    const post = data?.nodeProjectsGoel
    const projects = data?.allNodeSubministry
    const getMinsitrySocialMedia = () => {
      if(post?.field_social_media && post?.field_social_media.length > 0){
        return post.field_social_media.map(item => ({
          field_name: item.first,
          url: item.second
        }));
      }
    }

    const { t } = useContext(LanguageContext)
    const translationPaths = {
     en: `/en${post?.path?.alias}`,
     es: `/es${post?.path?.alias}`,
     ca: `${post?.path?.alias}`
   }

    const getText = (generalText, fieldName) => {
      switch (fieldName) {
        case 'email':
            if(post?.field_email){
              return post?.field_email
            }
            return generalText
          break;
        case 'schedule':
            if(post?.field_schedule){
              return post?.field_schedule
            }
          return generalText
        break;
        case 'address':
            if(post?.field_address){
              return post?.field_address
            }
        return generalText
        break;
        case 'phone':
            if(post?.field_phone){
              return post?.field_phone
            }
        return generalText
        break;
        default:
          return generalText
      }
    }


    return (
        <div className="page-interior">
          <Layout
            translationPaths={translationPaths}
            location={location}
            langcode={post.langcode}
          >
          <Seo
              title={post.title}
              lang={post.langcode}
              description={post.body.summary}
              image={post?.relationships?.field_image?.image_style_uri?._653_432}
            />
            <Header
              lang={pageContext?.langcode}
              location={location}
             />
            <section className="m-t-64 m-b-80 small-m-t-40 small-m-b-40 medium-m-b-24">
              <div className="container">
                <div className="row small-m-0">
                  <div className="col-md-10 offset-md-1 small-p-l-16 medium-100">
                    <h1 class="orange d-flex justify-content-center p-t-72 p-b-25 h1-int-small-font small-p-t-50 small-p-b-13 medium-p-t-24 h1-int-medium-font">
                      {post?.title}
                    </h1>
                    <Description
                      title={post?.body?.summary}
                      t={t}
                      showKeepReading={true}
                    />
                 </div>
                </div>
                <div className="row">
                 <div className="col-md-10 offset-md-1 img-interior-ministry p-0 small-p-l-16 medium-100">
                   <img
                    src={post?.relationships?.field_image?.image_style_uri?._1103x488}
                    alt={post?.title}
                    />
                 </div>
                </div>
              </div>
              </section>
              <section className="interior-content">
               <div className="container">
                <div className="row small-m-0 small-p-l-r-20">
                  <div className="col-12 col-md-6 p-0 m-r-20 offset-md-1 small-m-r-0 small-p-l-r-5 medium-m-l-0">
                    <div
                      dangerouslySetInnerHTML={{__html: post?.body?.processed}}
                      className="simple-page p-b-80 small-p-b-40"
                    />
                  </div>
                  <div className="col-12 col-md-3 offset-1 offset-md-1  sidebar p-0 small-m-b-32 m-b-58">
                    <MediaList mediaList={post?.relationships?.field_image_sidebar} />

                    {contactItems  && contactItems.length && contactItems.map((item, index) => {
                      return(
                        <InteriorContactItem
                          key={item.key}
                          icon={item.icon}
                          text={getText(item.text, item.field_name)}
                          socialmedia={getMinsitrySocialMedia()}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
              </section>
              {/*
              <MinistryProjects
                post={post}
                projects={projects}
               />*/
               }

            <Footer
              lang={pageContext?.langcode}
              location={location}
             />
          </Layout>
        </div>
    )
}


export const pageQuery = graphql`
query PageProject ($drupal_internal__nid: Int, $langcode: String!) {
    nodeProjectsGoel(
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ){
        title
        body{
              value
              summary
              processed
        }
      field_social_media {
          first
          second
      }
      relationships {
        field_image_sidebar {
          ... on media__image {
            id
            name
            relationships {
              field_media_image {
                image_style_uri {
                  large
                  wide
                }
              }
            }
          }
          ... on media__video {
            id
            name
            field_media_oembed_video
          }
        }
        field_image {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            _1103x488
            large
            medium
            thumbnail
            wide
          }
        }
      }
    sticky
    field_email
    field_address: field_direction
    field_phone
    field_schedule
    field_weight
    }
 }
`

export default PageProject
