import React from 'react';
import ReactPlayer from 'react-player/youtube';

const MediaList = ({ mediaList }) => {
  return (
    <div className="media-list">
      {mediaList.map((media) => (
        <MediaComponent className="media m-b-24" key={media.id} media={media} />
      ))}
    </div>
  );
}

const MediaComponent = ({ media, className }) => {
  if (media.relationships?.field_media_image?.image_style_uri) {
    const imageStyles = media.relationships.field_media_image.image_style_uri;

    const imageUrl = imageStyles.wide; // Choose the image with key _1440x408

    return (
      <div
        className={className}
      >
      <a
        href={imageUrl}
        target="_blank"
        alt={media.name}
      >
        <img src={imageUrl} alt={media.name} />
      </a>
      </div>
    );
  } else if (media.field_media_oembed_video) {
    return (
      <div className={className}>
        <ReactPlayer
          url={media.field_media_oembed_video}
        />
      </div>
    );
  } else {
    return null;
  }
}

export default MediaList;
